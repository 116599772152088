export class AuthClient {
  async init() {
    try {
      const response = await fetch('/api/v2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: 'query { viewer { __typename } }' }),
      });
      await response.json();
      return true;
    } catch (e) {
      // Assume the user is not authenticated
      return false;
    }
  }
}
